import React, { useState, useEffect } from "react";

const Pagination = (props) => {
  const { listItem, onChange } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  // Tính toán số trang
  const totalPages = Math.ceil(listItem.length / itemsPerPage);

  useEffect(() => {
    // Lấy phần của mảng dữ liệu hiện tại để hiển thị
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentItems = listItem.slice(startIndex, endIndex);
    onChange(currentItems);
  }, [currentPage, listItem]);

  // Xử lý sự kiện khi người dùng chuyển trang
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Hiển thị phân trang và danh sách các phần tử trên trang hiện tại
  return (
    <div>
      <ul className="pagination mb-0">
        {Array.from({ length: totalPages }, (_, index) => (
          <li key={index} className={index + 1 === currentPage && "active"}>
            <button
              className="page"
              type="button"
              onClick={() => handlePageChange(index + 1)}>
              {index + 1}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Pagination;
