import axiosClient from "./axiosClient";

const notiApi = {
  getAllNoti: () => axiosClient.get(`noti/getList`),
  update: (body) => axiosClient.post(`noti/update/${body.id}`, body),
  create: (body) => axiosClient.post(`noti/create`, body),
  delete: (id) => axiosClient.get(`noti/delete/${id}`),
};

export default notiApi;
