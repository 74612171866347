const ThongKe = ({ count }) => {
  return (
    <div className="row g-3 mb-3">
      <div className="col-md-6 col-xxl-4">
        <div className="card h-md-100 ecommerce-card-min-width">
          <div className="card-header pb-0">
            <h6 className="mb-0 mt-2 d-flex align-items-center">
              Tổng Số Thành Viên
            </h6>
          </div>
          <div className="card-body d-flex flex-column justify-content-end">
            <p className="font-sans-serif lh-1 mb-1 fs-4">{count?.userCount}</p>
          </div>
        </div>
      </div>
      <div className="col-md-6 col-xxl-4">
        <div className="card h-md-100 ecommerce-card-min-width">
          <div className="card-header pb-0">
            <h6 className="mb-0 mt-2 d-flex align-items-center">
              Tổng Số Bài Thi
            </h6>
          </div>
          <div className="card-body d-flex flex-column justify-content-end">
            <p className="font-sans-serif lh-1 mb-1 fs-4">
              {count?.incompleteQuizCount}
            </p>
          </div>
        </div>
      </div>
      <div className="col-md-6 col-xxl-4">
        <div className="card h-md-100 ecommerce-card-min-width">
          <div className="card-header pb-0">
            <h6 className="mb-0 mt-2 d-flex align-items-center">
              Tổng Số Bài Thi Hoàn Thành
            </h6>
          </div>
          <div className="card-body d-flex flex-column justify-content-end">
            <p className="font-sans-serif lh-1 mb-1 fs-4">
              {count?.completeQuizCount}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThongKe;
