import { useState } from "react";
import { useEffect } from "react";
import authApi from "../api/authApi";
import Pagination from "../components/common/Pagnavigation";
import { unit_type } from "./const";
import { AudioOutlined } from "@ant-design/icons";
import { Input, Space } from "antd";
const { Search } = Input;
const Member = () => {
  const [listMember, setListMember] = useState([]);
  const [listMemberCurrent, setListMemberCurrent] = useState([]);
  const [listMemberPag, setListMemberPag] = useState([]);
  useEffect(() => {
    const getListMember = async () => {
      try {
        const res = await authApi.getAll();
        const data = res.filter((v) => v.user.username !== "admin");
        setListMemberCurrent(data);
        setListMember(data);
      } catch (error) {
        console.log(error);
      }
    };
    getListMember();
  }, []);

  const onSearch = (value) => {
    const searchTerm = value.toLowerCase();

    if (searchTerm === "") {
      setListMember(listMemberCurrent);
    } else {
      const results = listMemberCurrent.filter(
        (user) =>
          user.user.username.toLowerCase().includes(searchTerm) ||
          (user.user.fullName &&
            user.user.fullName.toLowerCase().includes(searchTerm))
      );
      setListMember(results);
    }
  };
  return (
    <div
      className="card mb-3"
      id="ordersTable"
      data-list='{"valueNames":["order","date","address","status","amount"],"page":10,"pagination":true}'>
      <div className="card-header">
        <div className="row flex-between-center" style={{ padding: "0 16px" }}>
          <div className="col-4 col-sm-auto d-flex align-items-center pe-0">
            <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Thành viên</h5>
          </div>
          {/* <div className="col-8 col-sm-auto text-end ps-0">
            <div id="orders-actions">
              <button className="btn btn-falcon-default btn-sm" type="button">
                <span
                  className="fas fa-plus"
                  data-fa-transform="shrink-3 down-2"
                />
                <span className="d-none d-sm-inline-block ms-1">New</span>
              </button>
            </div>
          </div> */}
        </div>
      </div>
      <div className="card-body p-0">
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            marginBottom: "10px",
          }}>
          <Search
            placeholder="Tìm kiếm"
            onSearch={onSearch}
            style={{
              width: 200,
            }}
          />
        </div>
        <div className="table-responsive scrollbar">
          <table className="table table-sm table-striped fs--1 mb-0 overflow-hidden">
            <thead className="bg-200 text-900">
              <tr>
                <th>STT</th>
                <th className="sort pe-1 align-middle white-space-nowrap">
                  Tên
                </th>
                <th className="sort pe-1 align-middle white-space-nowrap">
                  UserName
                </th>
                <th className="sort pe-1 align-middle white-space-nowrap">
                  Điểm
                </th>
                <th className="sort pe-1 align-middle white-space-nowrap">
                  Đơn vị
                </th>
                <th className="no-sort" />
              </tr>
            </thead>
            <tbody className="list" id="table-orders-body">
              {listMemberPag &&
                listMemberPag.map((v, i) => (
                  <tr className="btn-reveal-trigger">
                    <td className="align-middle" style={{ width: "28px" }}>
                      {i + 1}
                    </td>
                    <td
                      className="order py-2 align-middle white-space-nowrap"
                      style={{ width: "200px" }}>
                      {v?.user?.fullName}
                    </td>
                    <td
                      className="order py-2 align-middle white-space-nowrap"
                      style={{ width: "200px" }}>
                      {v?.user?.username}
                    </td>
                    <td
                      className="order py-2 align-middle white-space-nowrap"
                      style={{ width: "200px" }}>
                      {v?.totalScore}
                    </td>
                    <td className="date py-2 align-middle">
                      {
                        unit_type.find((item) => item.id == v?.user?.unit)
                          ?.label
                      }
                    </td>
                    <td className="py-2 align-middle white-space-nowrap text-end"></td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="card-footer">
        <div className="d-flex align-items-center justify-content-center">
          <button
            className="btn btn-sm btn-falcon-default me-1"
            type="button"
            title="Previous"
            data-list-pagination="prev">
            <span className="fas fa-chevron-left" />
          </button>
          <Pagination
            listItem={listMember}
            onChange={(data) => setListMemberPag(data)}
          />
          <button
            className="btn btn-sm btn-falcon-default ms-1"
            type="button"
            title="Next"
            data-list-pagination="next">
            <span className="fas fa-chevron-right"> </span>
          </button>
          <button
            className="btn btn-sm btn-falcon-default ms-1"
            type="button"
            title="Next"
            data-list-pagination="next">
            <span>Tổng 10/{listMember?.length}</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Member;
