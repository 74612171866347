import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import boardApi from "../../api/boardApi";
import { setBoards } from "../../redux/features/boardSlice";

const Sidebar = () => {
  const user = useSelector((state) => state.user.value);
  const boards = useSelector((state) => state.board.value);
  const navigate = useNavigate();
  const router = useLocation();
  const { pathname } = router;
  const dispatch = useDispatch();
  const { boardId } = useParams();
  const [activeIndex, setActiveIndex] = useState(0);

  const sidebarWidth = 250;

  const logout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  return (
    <nav
      className="navbar navbar-light navbar-vertical navbar-expand-xl"
      style={{ display: "inline" }}>
      <div className="d-flex align-items-center">
        <div className="toggle-icon-wrapper">
          <button
            className="btn navbar-toggler-humburger-icon navbar-vertical-toggle"
            data-bs-toggle="tooltip"
            data-bs-placement="left"
            aria-label="Toggle Navigation"
            data-bs-original-title="Toggle Navigation">
            <span className="navbar-toggle-icon">
              <span className="toggle-line" />
            </span>
          </button>
        </div>
        <a className="navbar-brand" href="">
          <div className="d-flex align-items-center py-3">
            <img
              className="me-2"
              src="https://tuyengiao-tracnghiem.laocai.gov.vn/images/logo-dang.png"
              alt=""
              width={40}
            />
          </div>
        </a>
      </div>
      <div className="collapse navbar-collapse" id="navbarVerticalCollapse">
        <div className="navbar-vertical-content scrollbar">
          <ul className="navbar-nav flex-column mb-3" id="navbarVerticalNav">
            <li className="nav-item">
              {/* parent pages*/}
              <a
                className="nav-link dropdown-indicator"
                href="#dashboard"
                role="button"
                data-bs-toggle="collapse"
                aria-expanded="true"
                aria-controls="dashboard">
                <div className="d-flex align-items-center">
                  <span class="fas fa-chart-pie"></span>
                  <span className="nav-link-text ps-1">Dashboard</span>
                </div>
              </a>
              <ul className="nav collapse show" id="dashboard">
                <li className="nav-item">
                  <a
                    className={`nav-link ${pathname === "/" && "active"}`}
                    href="/">
                    <div className="d-flex align-items-center">
                      <span className="nav-link-text ps-1">Thống kê</span>
                    </div>
                  </a>
                  {/* more inner pages*/}
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link ${pathname === "/member" && "active"}`}
                    href="/member">
                    <div className="d-flex align-items-center">
                      <span className="nav-link-text ps-1">Thành Viên</span>
                    </div>
                  </a>
                  {/* more inner pages*/}
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link ${
                      pathname === "/questions" && "active"
                    }`}
                    href="/questions">
                    <div className="d-flex align-items-center">
                      <span className="nav-link-text ps-1">Câu Hỏi</span>
                    </div>
                  </a>
                  {/* more inner pages*/}
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link ${pathname === "/noti" && "active"}`}
                    href="/noti">
                    <div className="d-flex align-items-center">
                      <span className="nav-link-text ps-1">Thông báo</span>
                    </div>
                  </a>
                  {/* more inner pages*/}
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Sidebar;
