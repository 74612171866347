import axiosClient from "./axiosClient";

const authApi = {
  signup: (params) => axiosClient.post("auth/signup", params),
  login: (params) => axiosClient.post("auth/loginAdmin", params),
  getAll: () => axiosClient.get("auth/getAllUser"),
  verifyToken: () => axiosClient.post("auth/verify-token-admin"),
};

export default authApi;
