import { useState, useEffect } from "react";
import questionApi from "../api/questionApi";
import Pagination from "../components/common/Pagnavigation";
import { Input, Radio, Space, Drawer, Button, notification, Alert } from "antd";
import EditInline from "../components/common/EditInline";
import { SmileOutlined } from "@ant-design/icons";
const { Search } = Input;
const listAnswers = [
  {
    id: 1,
    answer: "Option 1",
    isCorrect: false,
  },
  {
    id: 2,
    answer: "Option 2",
    isCorrect: false,
  },
  {
    id: 3,
    answer: "Option 3",
    isCorrect: false,
  },
  {
    id: 4,
    answer: "Option 4",
    isCorrect: false,
  },
];
const Question = () => {
  const [listQuestion, setListQuestion] = useState([]);
  const [listQuestionPag, setListQuestionPag] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [showErr, setShowErr] = useState(false);
  const [answers, setAnswers] = useState(listAnswers);
  const [valueRadio, setValueRadio] = useState();
  const [valueAdd, setValueAdd] = useState("");
  const [nameQuestion, setNameQuestion] = useState("");
  const [isEdit, setEdit] = useState(false);
  const [questionId, setQuestionId] = useState("");
  const onChangeRadio = (e) => {
    const cloneAnswer = answers.map((v) => {
      return {
        ...v,
        isCorrect: false,
      };
    });
    const index = answers.findIndex((item) => item.id === e.target.value);
    cloneAnswer[index].isCorrect = true;
    setAnswers(cloneAnswer);
    setValueRadio(e.target.value);
  };

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
    setEdit(false);
    setNameQuestion("");
    setAnswers([]);
  };
  const getQuestion = async () => {
    try {
      const res = await questionApi.getAll();
      setListQuestion(res);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getQuestion();
  }, []);

  const handleEdit = (id, index, value) => {
    const cloneAnswer = [...answers];
    cloneAnswer[index].answer = value;
    setAnswers(cloneAnswer);
  };

  const handleDelete = (id) => {
    const newAnswer = answers.filter((v) => v.id !== id);
    setValueRadio("");
    setAnswers(newAnswer);
  };

  const handleAddRep = () => {
    const cloneAnswer = [...answers];
    const newItem = {
      id: cloneAnswer.length + 1,
      answer: valueAdd || `Option ${cloneAnswer.length + 1}`,
      isCorrect: false,
    };
    setValueAdd("");
    cloneAnswer.push(newItem);
    setAnswers(cloneAnswer);
  };
  const createQuestion = async (params) => {
    try {
      await questionApi.add(params);
      getQuestion();
      setOpen(false);
      setNameQuestion("");
      setAnswers(listAnswers);
      setValueRadio("");
      setLoading(false);
    } catch (err) {
      alert("Tên câu hỏi không được trùng");
      setLoading(false);
    }
  };

  const updateQuestion = async (params) => {
    try {
      params.questionId = questionId;
      await questionApi.update(params);
      getQuestion();
      setOpen(false);
      setNameQuestion("");
      setAnswers(listAnswers);
      setValueRadio("");
      setLoading(false);
    } catch (err) {
      alert("Tên câu hỏi không được trùng");
      setLoading(false);
    }
  };
  const handleSubmitQuestion = async () => {
    const params = {
      answers: answers.map((v) => {
        return {
          isCorrect: v.isCorrect,
          answer: v.answer,
        };
      }),
      question: nameQuestion,
    };
    const check = answers.find((item) => item.isCorrect === true);
    if (!check || !nameQuestion) {
      setShowErr(true);
    } else {
      setLoading(true);
      if (isEdit) {
        await updateQuestion(params);
      } else {
        await createQuestion(params);
      }
      setShowErr(false);
    }
  };

  const getDetail = (data) => {
    setNameQuestion(data?.question);
    setQuestionId(data?._id);
    const listAnswers = data?.answers.map((v) => {
      return {
        ...v,
        id: v._id,
      };
    });
    setAnswers(listAnswers);
    setValueRadio(listAnswers.find((v) => v.isCorrect).id);
    setOpen(true);
    setEdit(true);
  };

  const onDeleteQuestion = async (questionId) => {
    try {
      await questionApi.deleteQuestion(questionId);
      getQuestion();
    } catch (error) {}
  };
  useEffect(() => {
    if (!isEdit) {
      setAnswers(listAnswers);
    }
  }, [isEdit]);

  return (
    <div
      className="card mb-3"
      id="ordersTable"
      data-list='{"valueNames":["order","date","address","status","amount"],"page":10,"pagination":true}'>
      <div className="card-header">
        <div className="row flex-between-center" style={{ padding: "0 16px" }}>
          <div className="col-4 col-sm-auto d-flex align-items-center pe-0">
            <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Câu hỏi</h5>
          </div>
          <div className="col-8 col-sm-auto text-end ps-0">
            <div id="orders-actions">
              <button className="btn btn-falcon-default btn-sm" type="button">
                <span
                  className="fas fa-plus"
                  data-fa-transform="shrink-3 down-2"
                />
                <span
                  className="d-none d-sm-inline-block ms-1"
                  onClick={() => setOpen(true)}>
                  New
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="card-body p-0">
        <div className="table-responsive scrollbar">
          <table className="table table-sm table-striped fs--1 mb-0 overflow-hidden">
            <thead className="bg-200 text-900">
              <tr>
                <th>STT</th>
                <th className="sort pe-1 align-middle white-space-nowrap">
                  Câu hỏi
                </th>
                <th className="sort pe-1 align-middle white-space-nowrap">
                  Số lượng đáp án
                </th>
                <th className="no-sort" />
              </tr>
            </thead>
            <tbody className="list" id="table-orders-body">
              {listQuestionPag &&
                listQuestionPag.map((v, i) => (
                  <tr className="btn-reveal-trigger">
                    <td className="align-middle" style={{ width: "28px" }}>
                      {i + 1}
                    </td>
                    <td className="order py-2 align-middle white-space-nowrap">
                      {v?.question}
                    </td>
                    <td className="date py-2 align-middle">
                      {v?.answers?.length}
                    </td>
                    <td className="py-2 align-middle white-space-nowrap text-end">
                      <div className="dropdown font-sans-serif position-static">
                        <button
                          className="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal"
                          type="button"
                          id="order-dropdown-0"
                          data-bs-toggle="dropdown"
                          data-boundary="viewport"
                          aria-haspopup="true"
                          aria-expanded="false">
                          <span className="fas fa-ellipsis-h fs--1" />
                        </button>
                        <div
                          className="dropdown-menu dropdown-menu-end border py-0"
                          aria-labelledby="order-dropdown-0">
                          <div className="py-2">
                            <span
                              className="dropdown-item cursor-pointer"
                              onClick={() => getDetail(v)}>
                              Sửa
                            </span>
                            <div className="dropdown-divider" />
                            <span
                              className="dropdown-item text-danger cursor-pointer"
                              onClick={() => onDeleteQuestion(v?._id)}>
                              Xóa
                            </span>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="card-footer">
        <div className="d-flex align-items-center justify-content-center">
          <button
            className="btn btn-sm btn-falcon-default me-1"
            type="button"
            title="Previous"
            data-list-pagination="prev">
            <span className="fas fa-chevron-left" />
          </button>
          <Pagination
            listItem={listQuestion}
            onChange={(data) => setListQuestionPag(data)}
          />
          <button
            className="btn btn-sm btn-falcon-default ms-1"
            type="button"
            title="Next"
            data-list-pagination="next">
            <span className="fas fa-chevron-right"> </span>
          </button>
          <button
            className="btn btn-sm btn-falcon-default ms-1"
            type="button"
            title="Next"
            data-list-pagination="next">
            <span>Tổng 10/{listQuestion?.length}</span>
          </button>
        </div>
      </div>
      <Drawer
        width={700}
        title="Tạo câu hỏi"
        placement="right"
        onClose={onClose}
        open={open}>
        <div>
          {showErr && (
            <Alert
              message="Tên câu hỏi hoặc câu trả lời là bắt buộc!"
              type="error"
            />
          )}
          <Space.Compact style={{ width: "100%", margin: "5px" }}>
            <Input
              value={nameQuestion}
              placeholder="Nhập tên câu hỏi"
              onChange={(e) => setNameQuestion(e.target.value)}
            />
            <Button
              loading={loading}
              type="primary"
              onClick={handleSubmitQuestion}>
              {isEdit ? "Cập nhật" : "Tạo câu hỏi"}
            </Button>
          </Space.Compact>
          <Radio.Group onChange={onChangeRadio} value={valueRadio}>
            <Space direction="vertical">
              {answers &&
                answers.map((v, i) => (
                  <Radio value={v.id}>
                    <div style={{ display: "flex", gap: "5px" }}>
                      <Input
                        style={{ width: "100%" }}
                        value={v.answer}
                        onChange={(e) => handleEdit(v.id, i, e.target.value)}
                      />
                      <Button onClick={() => handleDelete(v.id)}>Xóa</Button>
                    </div>
                  </Radio>
                ))}
              <Radio value={null} disabled>
                <div style={{ display: "flex", gap: "5px" }}>
                  <Input
                    placeholder="Thêm câu trả lời"
                    onChange={(e) => setValueAdd(e.target.value)}
                  />
                  <Button type="primary" onClick={handleAddRep}>
                    Add
                  </Button>
                </div>
              </Radio>
            </Space>
          </Radio.Group>
        </div>
      </Drawer>
    </div>
  );
};

export default Question;
