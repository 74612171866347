import axiosClient from "./axiosClient";

const questionApi = {
  getAll: () => axiosClient.get("question"),
  getById: (id) => axiosClient.get(`quiz/${id}`),
  add: (body) => axiosClient.post(`question`, body),
  update: (body) => axiosClient.post(`question/update`, body),
  deleteQuestion: (id) => axiosClient.get(`question/deleteQuestion/${id}`),
};

export default questionApi;
