import axiosClient from "./axiosClient";

const quizApi = {
  getAllQuiz: () => axiosClient.get("quiz/getQuiz"),
  getById: (id) => axiosClient.get(`quiz/${id}`),
  getTopUsersByScore: () => axiosClient.get(`quiz/getTopUsersByScore`),
  getQuizDate: () => axiosClient.get(`quiz/getQuizDate`),
  createDateQuiz: (body) => axiosClient.post(`quiz/createDateQuiz`, body),
  countDashboard: () => axiosClient.get("quiz/countDashboard"),
};

export default quizApi;
