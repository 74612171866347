import { Button, DatePicker, Form, Popconfirm } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import quizApi from "../../api/quizApi";
const { RangePicker } = DatePicker;
const TopMember = ({ rank }) => {
  const [form] = Form.useForm();
  const rangeDate = Form.useWatch("rangeDate", form);
  const [quizDate, setQuizDate] = useState();

  const getQuizDate = async () => {
    try {
      const res = await quizApi.getQuizDate();
      const { startDate, endDate } = res;
      if (startDate && endDate) {
        form.setFieldsValue({
          rangeDate: [dayjs(startDate), dayjs(endDate)],
        });
      }
      setQuizDate(res);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getQuizDate();
  }, []);

  const confirm = async () => {
    try {
      if (!rangeDate) return;
      const startDate = dayjs(rangeDate[0]).format();
      const endDate = dayjs(rangeDate[1]).format();
      await quizApi.createDateQuiz({ startDate, endDate });
    } catch (error) {
      alert(error.data.message);
    }
  };

  return (
    <div className="row g-0">
      <div className="col-lg-12 mb-3">
        <Form layout="horizontal" form={form} style={{ display: "flex" }}>
          <Form.Item label="Thời gian thi" name="rangeDate">
            <RangePicker
              showTime={{ format: "HH" }}
              format={"DD/MM/YYYY - HH:mm"}
            />
          </Form.Item>
          <Popconfirm
            placement="top"
            title={"Cảnh báo"}
            description={
              "Nếu thay đổi sẽ thay đổi giờ thi của các bài thi đã làm"
            }
            onConfirm={confirm}
            okText="Yes"
            cancelText="No">
            <Button style={{ marginLeft: "20px" }} type="primary">
              Cập nhật thời gian thi
            </Button>
          </Popconfirm>
          <Button
            style={{ marginLeft: "20px" }}
            type="primary"
            onClick={() => {
              if (quizDate) {
                form.setFieldsValue({
                  rangeDate: [
                    dayjs(quizDate?.startDate),
                    dayjs(quizDate?.endDate),
                  ],
                });
              }
            }}>
            Reset
          </Button>
        </Form>
      </div>
      <div className="col-lg-12 mb-3">
        <div className="card h-lg-100 overflow-hidden">
          <div className="card-header bg-light">
            <div className="row align-items-center">
              <div className="col">
                <h6 className="mb-0">Top Thí Sinh</h6>
              </div>
            </div>
          </div>
          <div className="card-body p-0">
            {rank &&
              rank.map((v, i) => (
                <div className="row g-0 align-items-center py-2 position-relative border-bottom border-200">
                  <div className="col ps-x1 py-1 position-static">
                    <div className="d-flex align-items-center">
                      <div className="avatar avatar-xl me-3">
                        <div className="avatar-name rounded-circle bg-primary-subtle text-dark">
                          <span className="fs-0 text-primary">{i + 1}</span>
                        </div>
                      </div>
                      <div className="flex-1">
                        <h6 className="mb-0 d-flex align-items-center">
                          <a className="text-800 stretched-link" href="#!">
                            {v?.user?.fullName}
                          </a>
                          <span className="badge rounded-pill ms-2 bg-200 text-primary">
                            {v?.score}
                          </span>
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopMember;
