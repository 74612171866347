import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import quizApi from "../api/quizApi";
import ThongKe from "../components/common/ThongKe";
import TopMember from "../components/common/TopMember";
export const STATUS = {
  CHUA_THI: 1,
  DANG_THI: 2,
  DA_THI: 3,
};
const Home = () => {
  const navigate = useNavigate();
  const [rank, setRank] = useState([]);
  const [count, setCount] = useState();
  useEffect(() => {
    const getTopUsersByScore = async () => {
      try {
        const res = await quizApi.getTopUsersByScore();
        setRank(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    const countDashboard = async () => {
      try {
        const res = await quizApi.countDashboard();
        setCount(res);
      } catch (error) {
        console.log(error);
      }
    };

    // getListMember();
    getTopUsersByScore();
    countDashboard();
  }, []);

  return (
    <>
      <ThongKe count={count} />
      <TopMember rank={rank} />
    </>
  );
};

export default Home;
