import { Box } from "@mui/material";
import { useState, useEffect } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import authUtils from "../../utils/authUtils";
import Loading from "../common/Loading";
import Sidebar from "../common/Sidebar";
import { setUser } from "../../redux/features/userSlice";
import MenuTop from "../common/MenuTop";
import ThongKe from "../common/ThongKe";

const AppLayout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const router = useLocation();
  const { pathname } = router;
  useEffect(() => {
    const checkAuth = async () => {
      const user = await authUtils.isAuthenticated();
      if (!user) {
        navigate("/login");
      } else {
        // save user
        dispatch(setUser(user));
        setLoading(false);
      }
    };
    checkAuth();
  }, [navigate, pathname]);

  return loading ? (
    <Loading fullHeight />
  ) : (
    // <Box sx={{
    //   display: 'flex'
    // }}>
    //   <Sidebar />
    //   <Box sx={{
    //     flexGrow: 1,
    //     p: 1,
    //     width: 'max-content'
    //   }}>
    //     <Outlet />
    //   </Box>
    // </Box>
    <main className="main" id="top">
      <div className="container">
        <div className="row">
          <div className="col-md-2">
            <Sidebar />
          </div>
          <div className="col-md-10">
            <div className="content">
              <MenuTop />
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default AppLayout;
