import { Box, Button, TextField } from "@mui/material";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import authApi from "../api/authApi";

const Login = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [usernameErrText, setUsernameErrText] = useState("");
  const [passwordErrText, setPasswordErrText] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUsernameErrText("");
    setPasswordErrText("");

    const data = new FormData(e.target);
    const username = data.get("username").trim();
    const password = data.get("password").trim();
    console.log("hihihi", { username, password });
    let err = false;

    if (username === "") {
      err = true;
      setUsernameErrText("Please fill this field");
    }
    if (password === "") {
      err = true;
      setPasswordErrText("Please fill this field");
    }

    if (err) return;

    setLoading(true);

    try {
      const res = await authApi.login({ username, password });
      setLoading(false);
      localStorage.setItem("token", res.token);
      localStorage.setItem("user", JSON.stringify(res.user));
      navigate("/");
    } catch (err) {
      const errors = err.data.errors;
      errors.forEach((e) => {
        if (e.param === "username") {
          alert(e.msg);
        }
        if (e.param === "password") {
          alert(e.msg);
        }
      });
      setLoading(false);
    }
  };

  return (
    <main className="main" id="top">
      <div className="container">
        <div className="row flex-center min-vh-100 py-6">
          <div className="col-sm-10 col-md-8 col-lg-6 col-xl-5 col-xxl-4">
            <a className="d-flex flex-center mb-4" href="./">
              <img
                className="me-2"
                src="https://tuyengiao-tracnghiem.laocai.gov.vn/images/logo-dang.png"
                alt=""
                width={58}
              />
            </a>
            <div className="card">
              <div className="card-body p-4 p-sm-5">
                <div className="row flex-between-center mb-2">
                  <div className="col-auto">
                    <h5>Log in</h5>
                  </div>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <input
                      className="form-control"
                      name="username"
                      placeholder="Username"
                    />
                  </div>
                  <div className="mb-3">
                    <input
                      className="form-control"
                      name="password"
                      type="password"
                      placeholder="Password"
                    />
                  </div>
                  <div className="row flex-between-center">
                    <div className="col-auto">
                      <div className="form-check mb-0">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="basic-checkbox"
                          defaultChecked="checked"
                        />
                        <label
                          className="form-check-label mb-0"
                          htmlFor="basic-checkbox">
                          Remember me
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="mb-3">
                    <button
                      className="btn btn-primary d-block w-100 mt-3"
                      type="submit">
                      Log in
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Login;
