import { Button, Drawer, Input, Space } from "antd";
import { useEffect, useState } from "react";
import notiApi from "../api/notiApi";
import questionApi from "../api/questionApi";
import Pagination from "../components/common/Pagnavigation";
const { TextArea } = Input;
const Noti = () => {
  const [listNoti, setListNoti] = useState([]);
  const [listNotiPag, setListNotiPag] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [linkPath, setLinkPath] = useState("");
  const [isEdit, setEdit] = useState(false);
  const [id, setId] = useState("");
  const onClose = () => {
    setOpen(false);
    setEdit(false);
    setName("");
    setLinkPath("");
  };

  const getNoti = async () => {
    try {
      const res = await notiApi.getAllNoti();
      setListNoti(res);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getNoti();
  }, []);

  const createNoti = async (params) => {
    try {
      await notiApi.create(params);
      getNoti();
      setOpen(false);
      setName("");
      setLinkPath("");
      setLoading(false);
    } catch (err) {
      alert("Lỗi khi tạo");
      setLoading(false);
    }
  };

  const updateNoti = async (params) => {
    try {
      params.id = id;
      await notiApi.update(params);
      getNoti();
      setOpen(false);
      setName("");
      setLinkPath("");
      setLoading(false);
    } catch (err) {
      alert("Lỗi khi cập nhật");
      setLoading(false);
    }
  };
  const handleSubmitNoti = async () => {
    const params = {
      name: name,
      path: linkPath,
    };
    setLoading(true);
    if (isEdit) {
      await updateNoti(params);
    } else {
      await createNoti(params);
    }
    setLoading(false);
  };

  const getDetail = (data) => {
    setId(data._id);
    setName(data.name);
    setLinkPath(data.path);
    setOpen(true);
    setEdit(true);
  };

  const onDeleteNoti = async (id) => {
    try {
      await notiApi.delete(id);
      getNoti();
    } catch (error) {}
  };
  useEffect(() => {
    if (!isEdit) {
      setLinkPath("");
      setName("");
      setId("");
    }
  }, [isEdit]);

  return (
    <div
      className="card mb-3"
      id="ordersTable"
      data-list='{"valueNames":["order","date","address","status","amount"],"page":10,"pagination":true}'>
      <div className="card-header">
        <div className="row flex-between-center" style={{ padding: "0 16px" }}>
          <div className="col-4 col-sm-auto d-flex align-items-center pe-0">
            <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Thông báo</h5>
          </div>
          <div className="col-8 col-sm-auto text-end ps-0">
            <div id="orders-actions">
              <button className="btn btn-falcon-default btn-sm" type="button">
                <span
                  className="fas fa-plus"
                  data-fa-transform="shrink-3 down-2"
                />
                <span
                  className="d-none d-sm-inline-block ms-1"
                  onClick={() => setOpen(true)}>
                  New
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="card-body p-0">
        <div className="table-responsive scrollbar">
          <table className="table table-sm table-striped fs--1 mb-0 overflow-hidden">
            <thead className="bg-200 text-900">
              <tr>
                <th>STT</th>
                <th className="sort pe-1 align-middle white-space-nowrap">
                  Tên
                </th>
                <th className="sort pe-1 align-middle white-space-nowrap">
                  Nội dung
                </th>
                <th className="no-sort" />
              </tr>
            </thead>
            <tbody className="list" id="table-orders-body">
              {listNotiPag &&
                listNotiPag.map((v, i) => (
                  <tr className="btn-reveal-trigger">
                    <td className="align-middle" style={{ width: "28px" }}>
                      {i + 1}
                    </td>
                    <td className="order py-2 align-middle white-space-nowrap">
                      {v?.name?.substring(0, 50)}...
                    </td>
                    <td
                      className="date py-2 align-middle"
                      style={{ width: "200px" }}>
                      {v?.path?.substring(0, 50)}...
                    </td>
                    <td className="py-2 align-middle white-space-nowrap text-end">
                      <div className="dropdown font-sans-serif position-static">
                        <button
                          className="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal"
                          type="button"
                          id="order-dropdown-0"
                          data-bs-toggle="dropdown"
                          data-boundary="viewport"
                          aria-haspopup="true"
                          aria-expanded="false">
                          <span className="fas fa-ellipsis-h fs--1" />
                        </button>
                        <div
                          className="dropdown-menu dropdown-menu-end border py-0"
                          aria-labelledby="order-dropdown-0">
                          <div className="py-2">
                            <span
                              className="dropdown-item cursor-pointer"
                              onClick={() => getDetail(v)}>
                              Sửa
                            </span>
                            <div className="dropdown-divider" />
                            <span
                              className="dropdown-item text-danger cursor-pointer"
                              onClick={() => onDeleteNoti(v?._id)}>
                              Xóa
                            </span>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="card-footer">
        <div className="d-flex align-items-center justify-content-center">
          <button
            className="btn btn-sm btn-falcon-default me-1"
            type="button"
            title="Previous"
            data-list-pagination="prev">
            <span className="fas fa-chevron-left" />
          </button>
          <Pagination
            listItem={listNoti}
            onChange={(data) => setListNotiPag(data)}
          />
          <button
            className="btn btn-sm btn-falcon-default ms-1"
            type="button"
            title="Next"
            data-list-pagination="next">
            <span className="fas fa-chevron-right"> </span>
          </button>
          <button
            className="btn btn-sm btn-falcon-default ms-1"
            type="button"
            title="Next"
            data-list-pagination="next">
            <span>Tổng {listNoti?.length}</span>
          </button>
        </div>
      </div>
      <Drawer
        width={700}
        title="Tạo thông báo"
        placement="right"
        onClose={onClose}
        open={open}>
        <div>
          <Space.Compact style={{ width: "100%", margin: "5px" }}>
            Tên thông báo
          </Space.Compact>
          <Space.Compact style={{ width: "100%", margin: "5px" }}>
            <Input
              value={name}
              placeholder="Nhập tên thông báo"
              onChange={(e) => setName(e.target.value)}
            />
            <Button loading={loading} type="primary" onClick={handleSubmitNoti}>
              {isEdit ? "Cập nhật" : "Tạo Thông Báo"}
            </Button>
          </Space.Compact>
          <Space.Compact style={{ width: "100%", margin: "5px" }}>
            Nội dung
          </Space.Compact>
          <Space.Compact style={{ width: "100%", margin: "5px" }}>
            <TextArea
              rows={10}
              value={linkPath}
              placeholder="Nhập nội dung bài viết"
              onChange={(e) => setLinkPath(e.target.value)}
            />
          </Space.Compact>
        </div>
      </Drawer>
    </div>
  );
};

export default Noti;
