export const unit_type = [
  { id: 1, label: "Đoàn cơ sở Công ty CP Giầy da Thăng Long" },
  {
    id: 2,
    label:
      "Chi đoàn cơ sở Chi đoàn Công ty Cổ phần thiết bị cơ giới Thái Dương",
  },
  {
    id: 3,
    label: "Chi đoàn cơ sở Chi đoàn Công ty Cổ phần vật tư xăng dầu Miền Bắc",
  },
  {
    id: 4,
    label:
      "Chi đoàn cơ sở Chi đoàn Công ty Cổ phần đầu tư xây dựng và thương mại Thanh Giang",
  },
  { id: 5, label: "Chi đoàn cơ sở Chi đoàn Mầm non Ban Mai" },
  { id: 6, label: "Chi đoàn cơ sở Công ty TNHH Trung Thành" },
  { id: 7, label: "Chi đoàn cơ sở Công ty chứng nhận giám định ISOCERT" },
  { id: 8, label: "Chi đoàn cơ sở Công ty cổ phần Chứng nhận VinaCert" },
  {
    id: 9,
    label:
      "Chi đoàn cơ sở Công ty cổ phần Quản lý và xây dựng công trình Giao thông 236",
  },
  { id: 10, label: "Chi đoàn cơ sở Công ty cổ phần Sứ Viglacera Thanh Trì" },
  { id: 11, label: "Chi đoàn cơ sở Công ty cổ phần thực phẩm Hữu Nghị" },
  {
    id: 12,
    label:
      "Chi đoàn cơ sở Công ty cổ phần Đầu tư Phát triển ngành Nước và Môi trường",
  },
  { id: 13, label: "Chi đoàn cơ sở Dệt công nghiệp" },
  {
    id: 14,
    label: "Chi đoàn cơ sở Viện Kiểm định quốc gia vắc xin và sinh phẩm y tế",
  },
  { id: 15, label: "Đoàn cơ sở Cơ quan Ủy ban nhân dân Quận" },
  { id: 16, label: "Đoàn cơ sở Trung tâm Y tế quận Hoàng Mai" },
  { id: 17, label: "Chi đoàn cơ sở Bảo hiểm xã hội quận Hoàng Mai" },
  { id: 18, label: "Chi đoàn cơ sở Cơ quan Dân - Đảng quận" },
  { id: 19, label: "Chi đoàn cơ sở Kho bạc nhà nước Hoàng Mai" },
  { id: 20, label: "Chi đoàn cơ sở Tòa án nhân dân quậnHoàng Mai" },
  { id: 21, label: "Chi đoàn cơ sở Viện kiểm sát nhân dân quận Hoàng Mai" },
  { id: 22, label: "Chi đoàn cơ sở Văn phòng đăng ký đất đai" },
  { id: 23, label: "Đoàn cơ sở THPT Nguyễn Đình Chiểu" },
  { id: 24, label: "Đoàn cơ sở THPT Phương Nam" },
  { id: 25, label: "Đoàn cơ sở THPT Quốc tế Thăng Long" },
  { id: 26, label: "Đoàn cơ sở THPT Trần Quang Khải" },
  { id: 27, label: "Đoàn cơ sở Trung cấp Kinh tế Du lịch Hoa Sữa" },
  { id: 28, label: "Đoàn cơ sở Trung cấp Y Dược Tuệ Tĩnh" },
  {
    id: 29,
    label:
      "Đoàn cơ sở Trung tâm giáo dục nghề nghiệp - giáo dục thường xuyên quận Hoàng Mai",
  },
  { id: 30, label: "Đoàn cơ sở Trường THPT Hoàng Văn Thụ" },
  { id: 31, label: "Đoàn cơ sở Trường THPT Trương Định" },
  { id: 32, label: "Đoàn cơ sở Trường THPT Việt Nam - Ba Lan" },
  { id: 33, label: "Đoàn cơ sở Đại học Công nghệ và Quản lý Hữu Nghị" },
  { id: 34, label: "Chi đoàn cơ sở Trung cấp Y Dược Lê Hữu Trác" },
  { id: 35, label: "Đoàn cơ sở Phường Giáp Bát" },
  { id: 36, label: "Đoàn cơ sở Phường Hoàng Liệt" },
  { id: 37, label: "Đoàn cơ sở Phường Hoàng Văn Thụ" },
  { id: 38, label: "Đoàn cơ sở Phường Lĩnh Nam" },
  { id: 39, label: "Đoàn cơ sở Phường Mai Động" },
  { id: 40, label: "Đoàn cơ sở Phường Thanh Trì" },
  { id: 41, label: "Đoàn cơ sở Phường Thịnh Liệt" },
  { id: 42, label: "Đoàn cơ sở Phường Trần Phú" },
  { id: 43, label: "Đoàn cơ sở Phường Tân Mai" },
  { id: 44, label: "Đoàn cơ sở Phường Tương Mai" },
  { id: 45, label: "Đoàn cơ sở Phường Vĩnh Hưng" },
  { id: 46, label: "Đoàn cơ sở Phường Yên Sở" },
  { id: 47, label: "Đoàn cơ sở Phường Đại Kim" },
  { id: 48, label: "Đoàn cơ sở Phường Định Công" },
  { id: 49, label: "Đoàn cơ sở Công an quận" },
];
